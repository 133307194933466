import React, { useState } from "react";
import Logo from "./../images/UtvyaktaLogo.svg";
import emailjs from "emailjs-com";

import "./../Styles.css";

function Footer() {
  const [success, setSuccess] = useState(false);

  function sendEmail(event) {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_pr1xj5h",
        "template_wz9rurg",
        event.target,
        "user_KRu5oKGj1miFTJBXT5toF"
      )
      .then(
        (result) => {
          console.log(result.text);
          document.getElementById("myForm").reset();
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
          document.getElementById("myForm").reset();
        }
      );
  }

  return (
    <React.Fragment>
      <div className="container-fluid utv_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <img
                src={require("./../images/utv_white_logo.svg").default}
                width="250px"
                style={{ marginLeft: "-20px" }}
              />
              <div className="row " style={{ paddingTop: "20px" }}>
                <div className="col-md-3 borderRight borderBottom">
                  <p className="para">
                    Kommendörsgatan 11 <br /> 114 48 Stockholm SWEDEN
                  </p>
                </div>
                <div className="col-md-4 borderRight borderBottom">
                  <p className="para">
                    425 Mettupalayam Rd Tatabad, <br /> Coimbatore, Tamil Nadu
                    641043. INDIA
                    <br /><i className="fa fa-envelope"></i>&nbsp;
                    <a className="para" href="mailto:sales@utvyakta.com">sales@utvyakta.com</a>
                    <br /><i className="fa fa-phone"></i>&nbsp;
                    <a className="para" href="tel:+919843240322">+919843240322</a>
                  </p>
                </div>
                <div className="col-md-4  borderBottom">
                  <p className="para">
                    5500 Trabuco Rd Suite 150 <br />
                    Irvine, California, 92620 <br /> United States of America
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <ul className="footer-links">
                    <li>
                      <a href="/privacy">Privacy Policy & Cookie Policy</a>
                    </li>
                    {/* <li><a href="#">Terms of use</a></li>
                            <li><a href="#">Local language information</a></li>
                            <li><a href="#">Accessibility Statement</a></li> */}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12 copyrights">
                  Copyright 2021-2024 Utvyakta Solutions (P) Ltd., All Rights
                  Reserved.
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ paddingTop: "50px" }}>
              <h2 className="sub-heading white-text">Subscribe</h2>
              <p className="para">Stay current with our latest insights</p>

              <form
                className="row g-3"
                id="myForm"
                style={{ position: "relative" }}
                onSubmit={sendEmail}
              >
                <div className="col-auto">
                  <div className="row">
                    <div className="col-md-7">
                      <input
                        type="text"
                        name="email"
                        className="subscribe-textbox"
                        style={{ width: "250px" }}
                        required
                      />
                    </div>
                    <div className="col-md-5">
                      <button type="submit" className="pink-btn subscribe-btn">
                        Subscribe
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    {success && (
                      <div className="col-12 success-subscribe">
                        Subscribed successfully.
                      </div>
                    )}
                  </div>
                </div>
                <div className="footer-social-icons">
                  <a
                    href="https://twitter.com/utvyakta"
                    target="_blank"
                    className="twitter-icon"
                  >
                    <img src={require("./../images/twitter.png")} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/utvyakta-solutions-private-limited/"
                    target="_blank"
                  >
                    <img src={require("./../images/in.png")} />
                  </a>
                  <a href="https://www.facebook.com/utvyakta/" target="_blank">
                    <img src={require("./../images/facebook.png")} />
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
