import React, { useEffect, useState } from "react";
import Logo, { ReactComponent } from "./images/UtvyaktaLogo.svg";
import { usenavigate, useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import Header from "./Components/Header";
import Footer from "./Components/Footer";

import featuesTab1 from "./images/tab_1.png";
import featuesTab2 from "./images/tab_2.png";
import featuesTab3 from "./images/tab_3.png";
import featuesTab4 from "./images/tab_4.png";
import "./Styles.css";

function Home() {
  let navigate = useNavigate();

  const tabData = {
    Tab1: {
      Description:
        "Get real-time updates on your compressor health through an integrated dashboard.",
      ImageName: featuesTab1,
    },
    Tab2: {
      Description:
        "Save 15% on energy costs and 10% on maintenance costs of your compressors.",
      ImageName: featuesTab2,
    },
    Tab3: {
      Description:
        "Reduce unplanned downtime through predictive and preventive maintenance.",
      ImageName: featuesTab3,
    },
    Tab4: {
      Description:
        "Get the Advantage of advanced algorithms for accurate and real-time predictions",
      ImageName: featuesTab4,
    },
  };

  const [tabValue, setTabValue] = useState();
  const [tabImage, setTabImage] = useState();
  const [currentTabId, setCurrentTabId] = useState();

  const getFeatures = (event) => {
    let currentTab;
    if (event !== undefined) {
      currentTab = event.target.getAttribute("id");
    } else {
      currentTab = "Tab1";
    }

    setCurrentTabId(currentTab);
    setTabValue(tabData[currentTab].Description);
    setTabImage(tabData[currentTab].ImageName);
  };

  const setFeatures = (event) => {
    let currentTab;
    if (event !== undefined) {
      currentTab = event.target.value;
    } else {
      currentTab = "Tab1";
    }

    setCurrentTabId(currentTab);
    setTabValue(tabData[currentTab].Description);
    setTabImage(tabData[currentTab].ImageName);
  };

  const test = (event) => {
    alert("hi");
  };

  useEffect(() => {
    getFeatures();
  }, []);

  return (
    <React.Fragment>
      <Header />

      <div
        className="container-fluid homebanner-bg"
        style={{ height: "400px", paddingTop: "150px" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-6"
              style={{ "background-color": "#3B0099", paddingBottom: "12px" }}
            >
              <div className="row">
                <div className="col-7">
                  <h1
                    className="heading mobile-heading"
                    style={{ padding: "50px 40px 30px 40px" }}
                  >
                    Enabling intelligence for air compressors
                  </h1>
                  <button
                    onClick={() => navigate("/solutions")}
                    className="clear-btn mobile-clear-btn"
                    style={{ marginLeft: "40px" }}
                  >
                    Our Solution
                  </button>
                </div>
                <div className="col-5 padding-50">
                  <img alt="preview"
                    src={require("./images/banner-right-img.png")}
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row row-reverse">
          <div className="col-md-5 col-sm-12 padding-50 mobile-left-padding">
            <h1
              className="heading white-heading mobile-only"
              style={{ padding: "10px 40px 10px 40px" }}
            >
              Aligned to UN SDG Goal of industries, innovation & infrastructure
            </h1>
            <img
              src={require("./images/home_section_2.png")}
              width="80px" alt="preview"
              style={{ marginLeft: "200px" }}
            />
            <img src={require("./images/home_section_1.png")} width="300px" alt="preview"/>
          </div>
          <div className="col-md-6 col-sm-12 offset-1">
            <h1
              className="heading white-heading desktop-only"
              style={{ padding: "50px 40px 30px 40px" }}
            >
              Aligned to UN SDG Goal of industries, innovation & infrastructure
            </h1>
            <p className="para mobile-top-10" style={{ fontSize: "1.1em" }}>
              We are an Industrial Internet of Things (IIoT) solutions provider
              specializing in enabling intelligence of air compressors. Our
              vision is to reduce carbon emissions by 15% through smart
              technology interventions that reduce energy consumption and
              maintenance cost for industrial machines. Talk to us to understand
              how real-time monitoring of your compressors can save costs for
              you and concerns for the planet.
            </p>
            
              <button
                onClick={() => navigate("/aboutus#")}
                className="clear-btn pink-btn mobile-btn"
                style={{ marginTop: "15px" }}
              >
                Get to know us
              </button>
           
          </div>
        </div>
      </div>

      <div className="container-fluid grey-bg" style={{ display: "none" }}>
        <div className="container">
          <div className="col-12 text-center brand-heading">
            Brands who trust us
          </div>
          <div className="col-12 text-center">
            <div>
              <div className="brand-logos">
                {/* <img src={ require('./images/logo_1.png') } /> */}
                <img src={require("./images/logo_2.svg")} alt="preview"/>
                {/* <img src={ require('./images/logo_3.png') } /> */}
                <img src={require("./images/logo_4.png")} alt="preview"/>
                <img src={require("./images/logo_5.png")} alt="preview"/>
                <img src={require("./images/logo_6.png")} alt="preview"/>
                <img src={require("./images/logo_7.png")} alt="preview"/>
              </div>
              <div className="brand-logos">
                {/* <img src={ require('./images/logo_8.png') } />
                      <img src={ require('./images/logo_9.png') } /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid why-utv-container grey-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center brand-heading">Why Utvyakta</div>

            <div className="col-12">
              <div className="row why-utv-panel-column">
                <div className="col col-md-4 col-sm-12 text-center why-utv-panel">
                  <img src={require("./images/why_utv_1.png")} alt="preview"/>
                  <h2 className="sub-heading">
                    Deep understanding of air compressor performance &amp;
                    maintenance
                  </h2>
                </div>
                <div className="col col-md-4 col-sm-12 text-center why-utv-panel">
                  <img src={require("./images/why_utv_2.png")} alt="preview"/>
                  <h2 className="sub-heading">
                    Over 80% market share of key compressor OEMs in India
                  </h2>
                </div>
                <div className="col col-md-4 col-sm-12 text-center why-utv-panel">
                  <img src={require("./images/why_utv_3.png")} alt="preview" />
                  <h2 className="sub-heading">
                    Productivity &amp; energy efficiency through data and
                    real-time analytics
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid comparision_section">
        <div className="comaprision-table">
          Traditional Vs Smart Compressors
        </div>
        <img alt="preview"
          src={require("./images/desktop_comparision_img.png")}
          className="desktop-only"
        />
        <img alt="preview "
          src={require("./images/mobile_comparision_img.png")}
          className="mobile-only"
        />
      </div>

      <div className="container-fluid light-green-bg">
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mobile-only">
                  <div class="styled-select">
                    <select
                      className="currency-selector"
                      onChange={setFeatures}
                    >
                      <option id="Tab1" value="Tab1" selected>
                        Real-Time Analytics
                      </option>
                      <option id="Tab2" value="Tab2">
                        Energy Efficiency
                      </option>
                      <option id="Tab3" value="Tab3">
                        Predictive Maintenance
                      </option>
                      <option id="Tab4" value="Tab4">
                        AI-Enabled Backend
                      </option>
                    </select>
                    <span class="fa fa-sort-desc"></span>
                  </div>
                </div>
                <div className="col col-md-6 offset-1 mobile-padding mobile-only">
                  <div
                    className="col-md-12 offset-2 mobile-kompress-description"
                    style={{ padding: "100px 0 50px 0" }}
                  >
                    <h2 className="sub-heading">{tabValue}</h2>
                  </div>
                  <div
                    className="col-md-6 features-image"
                    style={{ marginLeft: "-30px" }}
                  >
                    {tabImage && <img src={tabImage} alt="preview"/>}
                  </div>
                </div>
                <div className="col col-md-5 desktop-only">
                  <div
                    className="col-12 mobile-kompress-logo"
                    style={{ paddingTop: "40px" }}
                  >
                    <img src={require("./images/kompressor_logo.png") } alt='preview' />
                  </div>
                  <div className="col-12 tabs-container">
                    <ul className="tabs">
                      <li
                        id="Tab1"
                        value="Tab1"
                        className={currentTabId === "Tab1" ? "Active" : ""}
                        onClick={getFeatures}
                      >
                        Real-Time Analytics
                      </li>
                      <li
                        id="Tab2"
                        value="Tab2"
                        className={currentTabId === "Tab2" ? "Active" : ""}
                        onClick={getFeatures}
                      >
                        Energy Efficiency
                      </li>
                      <li
                        id="Tab3"
                        value="Tab3"
                        className={currentTabId === "Tab3" ? "Active" : ""}
                        onClick={getFeatures}
                      >
                        Predictive Maintenance
                      </li>
                      <li
                        id="Tab4"
                        value="Tab4"
                        className={currentTabId === "Tab4" ? "Active" : ""}
                        onClick={getFeatures}
                      >
                        AI-Enabled Backend
                      </li>
                    </ul>

                    <Link to="/solutions#energy">
                      <button
                        onClick={() => navigate.push("/solutions#energy")}
                        className="clear-btn pink-btn"
                        style={{ marginTop: "15px" }}
                      >
                        Calculate Energy Savings
                      </button>
                    </Link>
                    <Link to="/solutions#solutionArchitecture">
                      <button
                        onClick={() => navigate.push("/solutions#architecture")}
                        className="clear-btn pink-btn"
                        style={{ marginTop: "15px" }}
                      >
                        Solution Architecture
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col col-md-6 offset-1 mobile-padding desktop-only">
                  <div
                    className="col-md-12 offset-2 mobile-kompress-description"
                    style={{ padding: "100px 0 50px 0" }}
                  >
                    <h2 className="sub-heading">{tabValue}</h2>
                  </div>
                  <div
                    className="col-md-6 features-image"
                    style={{ marginLeft: "-30px" }}
                  >
                    {tabImage && <img src={tabImage} alt="preview"/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid why-utv-container">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center brand-heading">In the news</div>

            <div className="col-md-12">
              <div className="row panel">
                <div className="col col-md-3 text-center why-utv-panel utv-card">
                  <img src={require("./images/news_1.png")} alt="preview"/>
                  <p className="para">
                    Cloudvidia Invests in Utvyakta for Software and Platforms to
                    Expand Reach of Industry 4.0
                  </p>
                  <div className="separator"></div>
                  <div className="readmore-panel">
                    <span className="read-panel-left">
                      <a
                        href="https://www.prnewswire.com/news-releases/cloudvidia-invests-in-utvyakta-for-software-and-platforms-to-expand-reach-of-industry-4-0--301219927.html"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </span>
                    <span className="read-panel-right">
                      <a
                        href="https://www.prnewswire.com/news-releases/cloudvidia-invests-in-utvyakta-for-software-and-platforms-to-expand-reach-of-industry-4-0--301219927.html"
                        target="_blank"
                      >
                        <img alt="preview"
                          src={require("./images/link.png")}
                          style={{ width: "45px" }}
                        />
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col offset-md-1 offset-sm-0 col-md-3 text-center why-utv-panel utv-card">
                  <img src={require("./images/news_2.png")} alt="preview"/>
                  <p className="para">Utvyakta sets up base in sweden</p>
                  <div className="separator"></div>
                  <div className="readmore-panel">
                    <span className="read-panel-left">
                      <a
                        href="https://press.investstockholm.com/posts/pressreleases/indias-utvyakta-solutions-picks-stockholm-as"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </span>
                    <span className="read-panel-right">
                      <a
                        href="https://press.investstockholm.com/posts/pressreleases/indias-utvyakta-solutions-picks-stockholm-as"
                        target="_blank"
                      >
                        <img
                          src={require("./images/link.png")}
                          style={{ width: "45px" }}
                        />
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col offset-md-1 offset-sm-0 col-md-3 text-center why-utv-panel utv-card">
                  <img src={require("./images/news_3.png")} alt="preview"/>
                  <p className="para">
                    Utvyakta emerges as a finalist in RTX innovation challenge
                  </p>
                  <div className="separator"></div>
                  <div className="readmore-panel">
                    <span className="read-panel-left">
                      <a
                        href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/t-hub-raytheon-technologies-select-three-startups-as-finalists-for-rtx-innovation-challenge/articleshow/77906609.cms?from=mdr"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </span>
                    <span className="read-panel-right">
                      <a
                        href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/t-hub-raytheon-technologies-select-three-startups-as-finalists-for-rtx-innovation-challenge/articleshow/77906609.cms?from=mdr"
                        target="_blank"
                        
                      >
                        <img alt="preview"
                          src={require("./images/link.png")}
                          style={{ width: "45px" }}
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Home;
