import React, { useState, useEffect, useRef } from "react"
import Logo from './../images/UtvyaktaLogo.svg';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import emailjs from 'emailjs-com';

import './../Styles.css';

function Header() {

  const ref = useRef();
  // State for our modal
  const [isModalOpen, setModalOpen] = useState(false);
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => {
    setModalOpen(false);
  });

  function sendEmail( event ) {
    
    event.preventDefault();

    emailjs.sendForm('service_pr1xj5h', 'template_kjsa4eo', event.target, 'user_KRu5oKGj1miFTJBXT5toF')
      .then((result) => {
          console.log(result.text);
          document.getElementById("myForm").reset();
          setState({ isPaneOpen: false });
      }, (error) => {
          console.log(error.text);
          document.getElementById("myForm").reset();
          setState({ isPaneOpen: false });
      }); 
  }

  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  // Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

function navigateToSubscribe() {
  setState({ isPaneOpen: true });
  var $;
  $ = require('jquery');
  $('#navbarNavAltMarkup').removeClass('show');
}

  return (
    <React.Fragment>
      <div className="container-fluid header">
          <div className="container">
            <div className="row">
              <div className="col-5">
                <a href="/"><img src={Logo} width="200px" height="auto" /></a>
              </div>
              <div className="col-7 text-right mobile-nav" ref={ref}>
              <nav className="navbar navbar-expand-lg navbar-light">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                  <div className="navbar-nav text-right">
                    <a className="nav-item nav-link active" href="/solutions">Our Solution</a>
                    <a className="nav-item nav-link" href="/aboutus">About us</a>
                    <a className="nav-item nav-link" target="_blank" href="https://kompress.ai">Login</a>
                    <a className="nav-item nav-link" href="#" onClick={() => navigateToSubscribe() }><span className="active-link">Schedule a Demo</span></a>
                  </div>
                </div>
              </nav>
              </div>
            </div>
          </div>
        </div>

        <SlidingPane
          className="sliding-panel"
          overlayClassName="some-custom-overlay-class"
          isOpen={state.isPaneOpen}
          title="Hey, it is optional pane title.  I can be React component too."
          subtitle="Optional subtitle."
          width="500px"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setState({ isPaneOpen: false });
          }}
        >
          
        <h2 className="sub-heading" style={{ "paddingTop":"50px", "paddingBottom":"10px" }}>Schedule a Demo</h2>
        
        <span class="fa fa-close close-icon" onClick={ () => setState({ isPaneOpen: false })}></span>
        <form onSubmit={ sendEmail } id="myForm">
          <div className="form-group">
            <label for="exampleInputEmail1">First Name <span className="mandatory">*</span></label>
            <input type="text" className="form-control" name="firstname" id="validationCustom05" required />
            <div className="invalid-feedback">Please provide a valid zip.</div>
          </div>

          <div className="form-group">
            <label for="exampleInputEmail1">Last Name <span className="mandatory">*</span></label>
            <input type="text" className="form-control" name="lastname" id="exampleInputEmail1" required />
          </div>

          <div className="form-group">
            <label for="exampleInputEmail1">Company <span className="mandatory">*</span></label>
            <input type="text" className="form-control" name="company" id="exampleInputEmail1" required />
          </div>

          <div className="form-group">
            <label for="exampleInputEmail1">Work Email <span className="mandatory">*</span></label>
            <input type="email" className="form-control" name="workemail" id="exampleInputEmail1" required />
          </div>

          <div className="form-group">
            <label for="exampleInputEmail1">Phone Number <span className="mandatory">*</span></label>
            <input type="text" className="form-control" name="phonenumber" id="exampleInputEmail1" required />
          </div>

          <button type="submit" className="clear-btn pink-btn schedulebtn" style={{ "marginTop":"15px", "width":"300px" }}>Sign up for a Demo</button>
        </form>
       
      </SlidingPane>
      

      </React.Fragment>
  );
}

export default Header;
